<template>
  <div class="container ">
     {{msg}}
  </div>
</template>

<script>
export default {name:"PPending",
 components: {},
 data(){
     return {
         msg: "working"
     }
 }

}
</script>

<style>

</style>